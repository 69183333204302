import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'

import TasksProjectKanbanHeader from './TasksProjectKanbanHeader'
import TasksProjectKanbanGrid from './TasksProjectKanbanGrid'

import { KanbanTasksType } from '../../../../types/custom/kanbanTasks.type'

import { useTasksProjectKanbanFiltersConfig } from './useTasksProjectKanbanFiltersConfig'
import { useFetchWithFiltersNew } from '../../../../hooks/useFetchWithFilters/useFetchWithFiltersNew'
import { getKanbanTasks } from '../../../../service/tasks/tasks'

import './tasksProjectKanban.scss'
import SubscriptionProgressLine from '../../../../components/other/SubscriptionProgressLine'
import {KanbanProjectTabs} from "../../../../components/other/KanbanProjectTabs/KanbanProjectTabs";
import Header from "../../../Layout/Layout/Header";
import searchIcon from "../../../../assets/images/icons/search.svg";
import {CSSTransition} from "react-transition-group";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import plusIcon from '../../../../assets/images/white-plus.svg'
import {MainContextProvider} from "../../../../context/MainContext";
import {clientKanbanService} from "../../../../service/client/kanban";
import {ClientKanbanHeader} from "../../../../components/other/ClientKanbanBoard/ClientKanbanHeader/ClientKanbanHeader";
import {da} from "suneditor/src/lang";
import customAxios from "../../../../service/api/interceptors";
import {KanbanTabs} from "../../../LegalManager/ManagerKanban/KanbanTabs";
import {useHistory, useLocation} from "react-router-dom";
import {getManagerKanbanService} from "../../../../service/manager/legalBoard";
import InfiniteScroll from "react-infinite-scroll-component";

const expand = 'executors,project,subscription,notifications,price_estimate'

let TasksProjectKanbanTimeout: NodeJS.Timeout | null;

function processTable(data: any) {
    const obj: any = {}
    let i = 0;

    Object.keys(data).map(key => {
        obj[key] = data[key].offers.map((el: any) => {
            i++
            return { ...el, index: i }
        })
    })

    return obj
}

function getPerPage() {
    return 6
}

const TasksProjectKanban:React.FC = () => {
  const { search } = useLocation()
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());
  const { client } = Object.fromEntries(urlSearchParams.entries()) || {  };
  const { setModalType, setLoading, userData } = useContext(MainContextProvider)
  const [kanbanTasksCount, setKanbanTasksCount] = useState({
      "business need to discuss": { count: 0 },
      "offers (pending)": { count: 0 },
      "tasks assigned": { count: 0 },
      "tasks done": { count: 0 }
  })
  const [kanbanTasks, setKanbanTasks] = useState({
      "business need to discuss": [],
      "offers (pending)": [],
      "tasks assigned": [],
      "tasks done": []
  })
    const [managers, setManagers] = useState([])
    const [manager, setManager] = useState([])
    const [kanbanStatuses, setKanbanStatuses] = useState<any>({})
    const [clients, setClients] = useState<any[]>([])
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const fetchMoreDataRef = useRef({});
    const totalCountRef = useRef(0);
    const history = useHistory()
    const [fetchItems, setFetchItems] = useState([{ item: 'a' }])

    // const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
  // const dropdownRef = useRef<HTMLDivElement>(null)
  // useOnClickOutside({ ref: dropdownRef, handler: () => setIsMobileFilterOpen(false) })

    const updateParam = useCallback((paramName, newValue) => {
        if (paramName !== 'page') {
            searchParams.set('page', '1')
        }
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${location.pathname}?${searchParams.toString()}`);
    }, [history, location.pathname, location.search]);

    const getKanbanData = async () => {
        const per_page = searchParams.get('page') ? getPerPage() * +(searchParams.get('page') || 1) : getPerPage()

      const { data, status } = await clientKanbanService(+client, 1, per_page)
        if(status === 200 && data) {
            setKanbanTasksCount(data.kanban)
            fetchMoreDataRef.current = processTable(data.kanban)

            let moreBiggerCount = 0
            Object.keys(data.kanban).map(key => {
                if (data.kanban[key].count > moreBiggerCount) {
                    moreBiggerCount = data.kanban[key].count
                }
            })
            totalCountRef.current = moreBiggerCount

            setKanbanTasks(processTable(data.kanban))
            setKanbanStatuses(data['kanban-statuses'])
            setManagers(data['other info'].managers)
            setManager(data['other info'].manager)
            setLoading(false)
        }
        TasksProjectKanbanTimeout = setTimeout(getKanbanData, 30000)
    }

    const getClientForManager = async () => {
        const { data: clientsData } = await customAxios.get(`/company/company/get-companies`)
        setClients(clientsData)
        if(client) {
            return;
        }
    }

    const fetchMoreData = async () => {
        if (+(searchParams.get('page') || 1) >= Math.ceil(Number(totalCountRef.current) / Number(getPerPage()))) {
            return
        }

        setLoading(true)
        try {
            const order_by = localStorage.getItem('manager-kanban-sort-value') || '1'
            const { data } = await clientKanbanService(+client, +(searchParams.get('page') || 1) + 1, getPerPage())
            const parseKanban = JSON.parse(JSON.stringify(fetchMoreDataRef.current))
            setFetchItems([...fetchItems, ...fetchItems])
            Object.keys(data.kanban).map(key => {
                parseKanban[key] = [...parseKanban[key], ...data.kanban[key].offers]
            })
            fetchMoreDataRef.current = parseKanban
            setKanbanTasks(parseKanban)
            updateParam('page', +(searchParams.get('page') || '1') + 1)
        } catch (e) {}

        setLoading(false)
    }


    useEffect(() => {
        document.title = 'Your Dashboard'
        getClientForManager().then()
        setLoading(true)
    }, [])

    useEffect(() => {
        if (clients.length > 0) {
            getKanbanData().then()
        }
    }, [clients])

    
    const isShowCreateLegalRequestButton = () => {
      const query_client_id: string | null = query?.client
      if (query_client_id) {
          const client_object: any = clients.find((el: { id: string, debt: string }) => +el.id === +query_client_id)
          if (client_object?.debt === "unsubscribed") {
              return false
          }

          if (clients.length === 1 && clients[0].debt === "unsubscribed") {
              return false
          }
      }

      return true
    }


  return (
    <div className={'tasks-project-kanban'}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: '20px' }}>
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <KanbanTabs dropdownOptions={[{id:'', name: 'All'}, ...clients]} />
            </div>
            <ClientKanbanHeader manager={manager} managers={managers} />
        </div>

        <InfiniteScroll
            dataLength={fetchItems.length}
            next={fetchMoreData}
            hasMore={true}
            loader={<></>}
        >
            <div className={'tasks-project-kanban__body'}>
                {
                    <TasksProjectKanbanGrid
                        kanbanTasksCount={kanbanTasksCount}
                        kanbanTasks={kanbanTasks}
                        kanbanStatuses={kanbanStatuses}
                        isCreateLegalRequestButton={isShowCreateLegalRequestButton()}
                    />
                }
            </div>
        </InfiniteScroll>
        {/*<div onClick={() => setModalType('create_task')} className={'mobile-crate-task-icon'}><img src={plusIcon} alt=""/></div>*/}
        {/*<div>
            {window.innerWidth <= 460 && <Header icons={() => <div ref={dropdownRef}>
                <a onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)} className="header__right-link"><img src={searchIcon} alt=""/></a>
                <CSSTransition in={isMobileFilterOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                    <div className={'tasks-project-kanban__header'}>
                        <TasksProjectKanbanHeader
                            selectedFilters={filters}
                            filterConfig={filterConfig}
                        />
                    </div>
                </CSSTransition>
            </div>} />}
            <KanbanProjectTabs
                filterConfig={filterConfig}
                onChange={() => {}}
                value={''}
            />
            {window.innerWidth > 460 && <div className={'tasks-project-kanban__header'}>
                <TasksProjectKanbanHeader
                    selectedFilters={filters}
                    filterConfig={filterConfig}
                />
            </div>}
            <SubscriptionProgressLine initialProjectId={subscriptionOptionId} />
        </div>*/}
    </div>
  )
}

export default TasksProjectKanban

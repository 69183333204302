import React, {useContext, useEffect, useState} from 'react'
import pdfIcon from '../../../../assets/images/nav/pdf.svg'
import moreIcon from '../../../../assets/images/moreVertical.svg'
import './payment-history.scss'
import { billingHistory, BillingHistoryType } from '../../../../service/settings'
import { dateFormatter } from '../../../../helpers/dateFormatter'
import {MainContextProvider} from "../../../../context/MainContext";
import {useHistory} from "react-router-dom";
import customAxios from "../../../../service/api/interceptors";

const switchService = (val: string) => {
  switch (val) {
    case 'retainer': return 'Monthly Plan Payment'
    case 'reminder': return 'Payment for Additional VLO Works'
    case 'trial': return 'Payment for Trial Period'
    default: return val
  }
}

const switchStatus = (val: string) => {
  switch (val) {
    case 'PAID': return 'Paid'
    case 'UNPAID': return 'Unpaid'
    case 'CLOSED': return 'Closed'
    default: return val
  }
}

const InvoiceCount = (id: string): string => {
  const initialNumber = '000000'
  return initialNumber.substring(0, (6 - id.length)) + id
}

type InvoiceType = {
  created_at: string
  description: string
  due_date: string
  id: string
  invoice_url: string
  receipt_url: string
  service: string
  service_list: string
  status: string
  sum: string
}

const PaymentHistory = () => {
  const history = useHistory()
  const [items, setItems] = useState<InvoiceType[]>([])
  const { setLoading } = useContext(MainContextProvider)

  const getAllData = async () => {
    setLoading(true)
    try {
      const { status, data } = await customAxios.get(`/company/invoices`)
      if (status === 200) {
        setItems(data)
      }
    } catch (e) {}
    setLoading(false)
  }

  useEffect(() => {
    (async () => {
      // const { data } = await billingHistory()
      await getAllData()
      document.title = "Invoices"
    })()
  }, [])

  if (window.innerWidth < 500) {
    return (
        <div className={'payment-history'}>
          <h2>{'Billing and Invoice History'}</h2>
          {
            items.map(({
              id, sum, service_list, status, created_at, service
            }) => (
                <div key={id} className={'payment-history-item'}>
                  <div className="payment-history-item__element">
                    <span>Number</span>
                    <p>{InvoiceCount(`${id}`)}</p>
                  </div>
                  <div className="payment-history-item__element">
                    <span>Created On</span>
                    <p>{created_at && +created_at > 0 && dateFormatter(created_at)}</p>
                  </div>
                  <div className="payment-history-item__element">
                    <span>Service</span>
                    <p>{switchService(service)}</p>
                  </div>
                  <div className="payment-history-item__element">
                    <span>Status</span>
                    <p>{switchStatus(status)}</p>
                  </div>
                  <div className="payment-history-item__element">
                    <span>Total Amount (ex. VAT)</span>
                    <p>{'$'}{sum}</p>
                  </div>
                  <div className={'payment-history-item__element'}>
                    {
                        (status === 'UNPAID' || status === 'PAID') &&
                        <button
                            onClick={() => history.push(`/checkout/${id}`)}
                            className="payment-history-item__btn">View</button>
                    }
                  </div>
                </div>
            ))
          }
        </div>
    )
  }

  return (
    <div className={'payment-history'}>
      <h2>{'Billing and Invoice History'}</h2>
      <table className={'payment-history__table'}>
        {items.length > 0 && <thead>
        <tr>
          <th>{'Number'}</th>
          <th>{'Created On'}</th>
          <th>{'Service'}</th>
          <th>{'Status'}</th>
          <th>{'Total Amount (ex. VAT)'}</th>
          <th>{'Options'}</th>
        </tr>
        </thead>}
        <tbody>
          {
            items.map(({
              id, sum, service_list, status, created_at, service
            }) => (
              <tr key={id}>
                <td>{InvoiceCount(`${id}`)}</td>
                <td>{created_at && +created_at > 0 && dateFormatter(created_at)}</td>
                <td style={{ textTransform: 'capitalize' }}>{switchService(service)}</td>
                <td style={{ textTransform: 'capitalize' }}>{switchStatus(status)}</td>
                <td>
                  {'$'}{sum}
                </td>
                <td style={{ width: '100px' }}>
                  <div>
                    {(status === 'UNPAID' || status === 'PAID') && <button
                        onClick={() => history.push(`/checkout/${id}`)}
                        style={{ width: '100px' }}
                        className={'btn-black'}
                    >View</button>}
                    {/*{status === 'PAID' && <button
                        onClick={() => history.push(`/checkout/${id}`)}
                        style={{ width: '100px' }}
                        className={'btn-black'}
                    >DOWNLOAD</button>}*/}
                    <>
                      {/*<a target={'_blank'} href={invoice_url}><img src={pdfIcon} alt={''} /></a>*/}
                      {/*<img src={moreIcon} alt={''} />*/}
                    </>
                  </div>
                </td>
              </tr>
            ))
          }
          {
              items.length === 0 && <tr>
                <td>The list is empty</td>
              </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

export default PaymentHistory

import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {PartnerRequestType} from "./PartnerRequestType";
import {PartnerRequestHeader} from "./viewComponents/PartnerRequestHeader";
import "./partner-request-view.scss"
import {CommentsContainer} from "../../../components/other/CommentsContainer/CommentsContainer";
import {LegalTeam} from "../../../components/other/ClientKanbanData/LegalTeam/LegalTeam";
import {PartnerRequestBody} from "./viewComponents/PartnerRequestBody";
import {PartnerRequestTaskTab} from "./viewComponents/PartnerRequestTaskTab";
import {toastifySuccess} from "../../../service/toastify/toastify";
import {ConfirmPartnerRequest} from "./viewComponents/ConfirmPartnerRequest";
import {AcceptFromVLOPartnerRequest, PublishPartnerRequest} from "./viewComponents/PublishPartnerRequest";
import {ConfirmClientPayment} from "./viewComponents/ConfirmClientPayment";
import {PartnerRequestTaskClientAccepted} from "./viewComponents/PartnerRequestTaskClientAccepted";
import {ProviderInfo} from "./viewComponents/ProviderInfo";
import {RequestSwitch} from "./viewComponents/RequestSwitch";
import {PartnerNotInterested} from "./viewComponents/PartnerNotInterested";
import {PartnerRequestExpired} from "./viewComponents/PartnerRequestExpired";
import {PrExpiredRightColumn} from "./viewComponents/PrExpiredRightColumn";
import {
    OfferAndPartnerRequestTabs
} from "../../../components/other/OfferAndPartnerRequestTabs/OfferAndPartnerRequestTabs";
import {GroupComments} from "../../../components/other/GroupComments/GroupComments";

export const ServiceProviderView = () => {
    const [fullScreenChat, setFullScreenChat] = useState(false)
    const { userData } = useContext(MainContextProvider)
    const history = useHistory()
    const { id }: { id: string } = useParams()
    const { setLoading, loading } = useContext(MainContextProvider)
    const [partnerRequestData, setPartnerRequestData] = useState<PartnerRequestType>({} as PartnerRequestType)

    const rules = {
        acceptUpdateTasks: ((userData?.status.type_code === 200 && +partnerRequestData?.status === 1) || (userData?.status.type_code === 300 && +partnerRequestData?.status > 3)),
        acceptPublishRequest: +partnerRequestData?.status === 1,
        showRequestAcceptBlock: (userData?.status.type_code === 200) && (+partnerRequestData.status === 0),
        showRequestPublishBlock: (userData?.status.type_code === 200) && (+partnerRequestData.status >= 1),
        showConfirmClientPayment: (userData?.status.type_code === 200) && (+partnerRequestData.status === 5) && (+partnerRequestData?.offer?.accepted !== 5),
        showProviderInfo: (userData?.status.type_code === 300 || userData?.status.type_code === 400),
        showTaskBlock: ((userData?.status.type_code === 200) && (+partnerRequestData.status !== 0 || +partnerRequestData.status === 5) && +partnerRequestData.status !== 6)
                    || ((userData?.status.type_code === 300 || userData?.status.type_code === 400) && ((+partnerRequestData.status === 3) || (+partnerRequestData.status === 4) || (+partnerRequestData.status === 5) || (+partnerRequestData.status === 6) || (+partnerRequestData.status === 7))),
        showVloHasToAccept: (userData?.status.type_code === 300) && (+partnerRequestData.status >= 3),
        acceptVloPublishRequest: (userData?.status.type_code === 300) && (+partnerRequestData.status === 3),
        showNotInterested: (userData?.status.type_code === 300 || userData?.status.type_code === 400) && +partnerRequestData.status === 2,
        showRequestExpired: (userData?.status.type_code === 300 || userData?.status.type_code === 400) && +partnerRequestData.status === 6,
        showRightColumnRequestExpired: (userData?.status.type_code === 200) && +partnerRequestData.status === 6,
    }

    const getData = async () => {
        setLoading(true)
        try {
            const { status, data } =
                await customAxios.get(`/partner-request/${id}?expand=pre_paid,manager,lawyers,price_estimate,offer_price,project,notifications,client,company`)
            if (status === 200) {
                setPartnerRequestData({
                    ...data,
                    offer_accepted: '' + data.offer.accepted,
                    //status: '7'
                })

                if (String(data.status) === '2' && userData?.status.type_code === 200) {
                    history.push('/dashboard')
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
        document.title = `Partner request #${id}`
    }, [id])

    const updatePartnerRequest = async (postData: any) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/partner-request/${id}/update`, {
                ...partnerRequestData,
                ...postData
            })
            if (status === 200) {
                await getData()
                toastifySuccess('Partner request has been updated')
            }
        } catch (e) {}
        setLoading(false)
    }

    const confirmPayment = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/partner-request/${id}/payment-confirmed`)
            if (status === 200) {
                await getData()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div className={`singleOffer task-view ${fullScreenChat && 'full-screen-chat'}`} style={{ position: 'relative' }}>
            <div className={'task-view__left-side'}>
                {partnerRequestData?.offer_id && !loading && userData?.status.type_code !== 200 && <OfferAndPartnerRequestTabs
                    activeRequestTab={'pr'}
                    setActiveRequestTab={(() => {
                    })}
                    offer_id={partnerRequestData.offer_id}
                    active={'partner_request'} pr_id={id}
                />}
                <PartnerRequestHeader data={partnerRequestData} request_id={+id} update={getData} />
                <div className={'partner-request-view__body'}>
                    <PartnerRequestBody
                        data={partnerRequestData}
                    />
                    <LegalTeam
                        items={[ { id: Number(partnerRequestData?.manager?.user_id) || 1, avatar_path: partnerRequestData?.manager?.avatar_url + '', fullname: partnerRequestData?.manager?.name + '', title: 'VLO', type: '300' } ]}
                    />
                </div>
                {rules.showRequestExpired &&
                    <PartnerRequestExpired />
                }
                {rules.showNotInterested &&
                    <PartnerNotInterested />
                }
                {rules.showConfirmClientPayment &&
                    <ConfirmClientPayment confirmPayment={confirmPayment} />}
                {rules.showTaskBlock && !rules.showConfirmClientPayment && <PartnerRequestTaskTab
                    request_id={id}
                    acceptUpdateTasks={rules.acceptUpdateTasks}
                    status={partnerRequestData.status}
                    updatePrice={getData}
                    requestData={partnerRequestData}
                />}
                {rules.showRequestPublishBlock && <PublishPartnerRequest
                    data={partnerRequestData}
                    acceptPublishRequest={rules.acceptPublishRequest}
                    updatePartnerRequest={updatePartnerRequest}
                />}
                {rules.showVloHasToAccept && <AcceptFromVLOPartnerRequest
                    data={partnerRequestData}
                    acceptPublishRequest={rules.acceptVloPublishRequest}
                    updatePartnerRequest={updatePartnerRequest}
                />}
            </div>
            <div className="task-view__right-side" style={{ width: '480px', maxWidth: '100%' }}>
                {
                    !fullScreenChat && rules.showRightColumnRequestExpired && <PrExpiredRightColumn request={partnerRequestData} />
                }
                {
                    !fullScreenChat && (userData?.status.type_code === 300 || userData?.status.type_code === 400) &&
                    <RequestSwitch offer_id={partnerRequestData.offer_id} request_id={id} />
                }
                {
                    !fullScreenChat && rules.showProviderInfo && <ProviderInfo provider={partnerRequestData?.provider} />
                }
                {
                    !fullScreenChat && rules.showConfirmClientPayment &&
                    <PartnerRequestTaskClientAccepted request={partnerRequestData} />
                }
                {!fullScreenChat && rules.showRequestAcceptBlock && <ConfirmPartnerRequest
                    updatePartnerRequest={updatePartnerRequest}
                />}
                {/*<CommentsContainer
                    entity_id={id}
                    postMessage={() => {}}
                    link={'/company/comment/'}
                    linkToSend={`/company/comment/create/partner-request/${id}`}
                    linkToGetMessages={'partner-request'}
                    isUnSubscribe={false}
                    disabledStartTimer={true}
                    websocketName={'partner-request'}
                    setMessages={() => {}}
                    fullScreenChat={fullScreenChat}
                    setFullScreenChat={setFullScreenChat}
                />*/}
                <div style={{ minWidth: '460px' }}>
                    <GroupComments
                        id={id}
                        entity={'partner-request'}
                        isUnSubscribe={false}
                        setTabsCounter={() => {}}
                        tabsCounter={{}}
                        offer_id={partnerRequestData.offer_id}
                    />
                </div>

            </div>
        </div>
    )
}

const ServiceProviderViewTab: React.FC<any> = ({ offer_id, history }) => {
    return (
        <div className="singleOffer__tabs">
            <div
                onClick={() => history.push(`/offer/${offer_id}`)}
                className={`singleOffer__tabs-item`}>
                Offer Draft
            </div>
            <div className={`singleOffer__tabs-item singleOffer__tabs-item-active`}>
                Partner Requests
            </div>
        </div>
    )
}
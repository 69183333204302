import { RouterType } from '../helpers/types'
import TasksKanban from '../containers/Lawyer/Task/TasksKanban'
import TaskView from '../containers/Client/Task/TaskView'
import Settings from '../containers/Settings'
import TasksProjectKanban from '../containers/Lawyer/Task/TasksProjectKanban'
import {TestForCors} from "../components/TestForCors";
import {OnBoarding} from "../containers/Client/Onboarding/Main/Onboarding";
import {Offers} from "../containers/Client/Onboarding/OfferList/Offers";
import {OfferView} from "../containers/Client/Onboarding/OfferView/OfferView";
import {Offer} from "../containers/Client/Offer/Offer";
import {LegalRequest} from "../containers/Client/LegalRequest/LegalRequest";
import {TestRoute} from "../containers/TestRoute";
import {DeliverableView} from "../containers/Client/Offer/DeliverableView";
import {Checkout} from "../containers/Settings/Routes/Checkout/Checkout";
import {FinishStep} from "../components/other/EmailHaveBeenSent/FinishStep";
import NotFound from "../containers/NotFound/NotFound";
import {Test} from "../components/other/Test";
import {Report} from "../containers/Client/Report/Report";
import {DocumentsList} from "../containers/Documents/DocumentsList";
import {SearchResults} from "../containers/Other/SearchResults/SearchResults";
import {BusinessGoalView} from "../containers/BusinessGoals/BusinessGoalView/BusinessGoalView";
import {BusinessGoalsVloKanban} from "../containers/BusinessGoals/BusinessGoalsVloKanban/BusinessGoalsVloKanban";

const CLIENT:RouterType[] = [
  /*{
    exact: true,
    path: '/olddashboard',
    title: 'Клиент',
    Component: TasksKanban,
  },*/
  {
    exact: false,
    path: '/404',
    title: 'Legal Request',
    Component: NotFound,
  },
  {
    exact: true,
    path: '/dashboard',
    title: 'Задачи по проектам',
    Component: TasksProjectKanban,
  },
  /*{
    exact: true,
    path: '/dataRoom',
    title: 'Клиент',
    Component: TasksKanban,
  },*/
  {
    exact: true,
    path: '/tasks/:id',
    title: 'Задача',
    Component: TaskView,
  },
  {
    exact: false,
    path: '/settings/',
    title: 'Настройки',
    Component: Settings,
  },
  {
    exact: false,
    path: '/onboarding',
    title: 'onBoarding',
    Component: OnBoarding,
  },
  {
    exact: false,
    path: '/offers',
    title: 'Настройки',
    Component: Offers,
  },
  {
    exact: false,
    path: '/onboarding-offer/:id',
    title: 'Offer',
    Component: Offer,
  },
  {
    exact: false,
    path: '/offer/:id',
    title: 'Offer',
    Component: Offer,
  },
  {
    exact: false,
    path: '/legal-request/:id',
    title: 'Legal Request',
    Component: LegalRequest,
  },
  {
    exact: false,
    path: '/test',
    title: 'Legal Request',
    Component: TestRoute,
  },
  {
    exact: false,
    path: '/test2',
    title: 'Legal Request',
    Component: Test,
  },
  {
    exact: false,
    path: '/checkout/:id',
    title: 'Legal Request',
    Component: Checkout,
  },
  {
    exact: false,
    path: '/deliverable-view/:id',
    title: 'Legal Request',
    Component: DeliverableView,
  },
  {
    exact: true,
    path: '/finish-bank-payment',
    title: 'finish-bank-payment',
    Component: FinishStep,
  },
  {
    exact: true,
    path: '/reports',
    title: 'reports',
    Component: Report,
  },
  {
    exact: true,
    path: '/document',
    title: 'Documents',
    Component: DocumentsList,
  },
  {
    exact: true,
    path: '/cors',
    title: 'Войти',
    Component: TestForCors,
  },
  {
    exact: false,
    path: '/search',
    title: 'Dashboard',
    Component: SearchResults,
  },
  {
    exact: false,
    path: '/business-goals/:id',
    title: 'Dashboard',
    Component: BusinessGoalView,
  },
  {
    exact: false,
    path: '/business-goals',
    title: 'Dashboard',
    Component: BusinessGoalsVloKanban,
  },
]

export default CLIENT

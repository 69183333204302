import React, {useCallback, useContext, useEffect, useState} from 'react'
import TaskBody from './other/TaskBody/TaskBody'
import Milestones from './other/Milestones'
import Tabs from '../../../../components/other/Tabs'
import './task-view.scss'
import Deliverables from './other/ Deliverables'
import { deleteSingleTask, getSingleTask, updateTask } from '../../../../service/tasks'
import { TaskStateType, TaskViewType } from './TaskView.type'
import Comments from './other/Comments'
import { toastifyError, toastifySuccess } from '../../../../service/toastify/toastify'
import { TaskType } from '../../../../types/task.type'
import { MainContextProvider } from '../../../../context/MainContext'
import { sendEvent } from '../../../../service/tracker'
import { useTranslation } from 'react-i18next'
import ClientAction from './other/ClientAction/ClientAction'
import ModalConfig from "../../../../components/ModalContainer/ModalConfig";
import bottomIcon from '../../../../assets/images/backBlackIcon.svg'

const TaskView:React.FC<any> = ({ match: { params: { id } }, history }) => {
  const { userData, loading, setLoading } = useContext(MainContextProvider)
  const [activeTab, setActiveTab] = useState('milestones')
  const { t } = useTranslation()
  const [ modalType, setModalType ] = useState('')
  const [state, setMyState] = useState<TaskStateType>({
    title: '',
    price_estimate: 0,
    time_estimate: 0,
    deadline: +(new Date()),
    client_story: '',
    subscription: null,
    updated_at: '',
    created_at: '',
    project: null,
    executors: [],
    deliverables: '',
    milestones: [],
    client_actions: [],
    messages: [],
    status: '',
    type: '',
    project_id: null,
    id,
    role: userData?.status.type,
    clients: [],
    archived: 0,
    total_price: 0,
    moveToArchive: () => {},
    deleteTask: () => {},
  })

  const setTaskData = (data:any) => {
    const {
      deliverables,
      milestones,
      executors,
      client_actions,
      messages,
      updated_at,
      created_at,
      subscription,
      type,
      project,
      status,
      title,
      client_story,
      deadline,
      time_estimate,
      price_estimate,
      project_id,
      clients,
      archived,
      total_price
    } = data

    setMyState(prevState => ({
      ...prevState,
      deliverables,
      milestones,
      executors,
      client_actions,
      messages: messages.reverse(),
      updated_at,
      created_at,
      subscription,
      type,
      project,
      status,
      title,
      client_story,
      deadline,
      time_estimate,
      price_estimate,
      project_id,
      clients,
      archived,
      total_price
    }))
  }

  const trackerEvent = (event_name: string, event_data?: { [key: string]: string }) => {

  }

  const getData = async () => {
    try {
      const res = await getSingleTask(id)
      setLoading(false)
      setTaskData(res.data)
      trackerEvent('task_opened', { task_status: res.data.status })
    } catch (e) {
      // @ts-ignore  FIX_ME
      if(e?.response?.data?.status === 404) history.push('/404?entity=task')
    }
  }

  useEffect(() => {
    (async () => {
      await getData()
    })()
  }, [id])

  const updateTaskFunc = async (data: any, postType?: string, message?: string) => {
    setLoading(true)
    const { status, price_estimate, deadline, time_estimate, project_id, title, client_story, type, archived, project = { subscription: [] } } = state;

    const dataForUpdate = {
      status,
      title,
      client_story,
      archived,
      budget: price_estimate,
      deadline: deadline + '',
      time_estimate: time_estimate + '',
      // price_estimate,
      project_id: project_id || 1,
      type: +type,
      ...data
    }

    // @ts-ignore
    if(project?.subscription && project.subscription[0]) {
      delete dataForUpdate.budget
    }

    try {
      const res = await updateTask(id, dataForUpdate)
      if(res.status === 200) {
        if(postType === 'archived'){
          history.push(`/projects/${state.project_id}`)
          toastifySuccess(t('taskBody.taskUpdated'))
        } else if(postType === "singleItem"){
          setModalType('');
          setMyState(prevState => ({ ...prevState, ...data }))
          toastifySuccess(message || t('taskBody.taskUpdated'))
          trackerEvent("task_updated", { action: 'follow_up_updated' })
        }
      }
    } catch (e) {
      toastifyError('Something went wrong')
    }
    setLoading(false)
  }

  const deleteTask = async () => {
    if (window.confirm('Are you sure?')) {
      try {
        const res = await deleteSingleTask(state.id)
        if (res.status === 204) {
          history.push(`/projects/${state.project_id}`)
          toastifySuccess('Task have been deleted')
        }
      } catch (e) {
        console.error('deleteTask', e)
      }
    }
  }


  return (
      <>
        <div onClick={() => history.goBack()}
             className={'mobile-back-to-all-task'}>
          <img src={bottomIcon} alt=""/>
          ALL TASKS
        </div>
        <div className={'task-view'}>
          {userData && (
              <div className={'task-view__left-side'}>
                <TaskBody
                    {...state}
                    setStatus={(status) => updateTaskFunc({status}, "singleItem")}
                    role={state.role || ''}
                    moveToArchive={() => {
                      if(window.confirm('Are you sure?')) updateTaskFunc({archived: 1}, "archived").then()
                    }}
                    deleteTask={deleteTask}
                    openEditTaskModal={() => setModalType('create_task')}
                />
                <Tabs
                    arr={[
                      {
                        Component:
                            <Milestones
                                task_id={id}
                                items={state.milestones}
                                setItems={(milestones) => setMyState(prevState => ({ ...prevState, milestones }))}
                                executor={state.executors && state.executors[0]}
                                other={{
                                  title: t('taskBody.milestoneTitle'),
                                  button: t('taskBody.addMilestone'),
                                  modalAddTitle: t('taskBody.addMilestoneModal'),
                                  modalUpdateTitle: t('taskBody.updateMilestoneModal'),
                                  buttonAllow: (state.role === 'provider' && state.status + "" !== "3"),
                                  checkboxAllow: state.role === 'provider' && state.status + "" !== "3",
                                  isPages: true,
                                  link: 'milestone',
                                  type: 200,
                                  prefix: 'milestones',
                                }}
                                role={state.role || ''}
                            />,
                        label: t('taskBody.milestones'),
                        value: 'milestones',
                      },
                      {
                        Component:
                            <Deliverables
                                state={state}
                                deliverables={state.deliverables}
                                changeDeliverables={(deliverables) => setMyState(prevState => ({ ...prevState, deliverables }))}
                                role={state.role || ''}
                                trackerEvent={trackerEvent}
                                updateTaskFunc={updateTaskFunc}
                            />,
                        label: t('taskBody.deliverables'),
                        value: 'deliverables',
                      },
                      {
                        Component:
                            <ClientAction
                                task_id={id}
                                items={state.client_actions}
                                setItems={(client_actions) => setMyState(prevState => ({ ...prevState, client_actions }))}
                                executor={state.clients[0]}
                                other={{
                                  title: t('taskBody.clientAction'),
                                  button: t('taskBody.addClientAction'),
                                  modalAddTitle: t('taskBody.addClientAction'),
                                  modalUpdateTitle: t('taskBody.updateClientAction'),
                                  buttonAllow: (state.role === 'provider' && state.status + "" !== "3"),
                                  checkboxAllow: state.role === 'client' && state.status + "" !== "3",
                                  type: 100,
                                  prefix: 'client_action',
                                  link: 'client_action',
                                }}
                                role={state.role || ''}
                            />,
                        label: t('taskBody.clientAction'),
                        value: 'client_action',
                      },
                    ]}
                    active={activeTab}
                    onChange={(e) => setActiveTab(e)}
                />
              </div>
          )}
          <div className={'task-view__right-side'}>
            <Comments
                messages={state.messages}
                updateMessages={(messages:any) => setMyState(prevState => ({ ...prevState, messages }))}
                task_id={id}
            />
          </div>

          <ModalConfig
              modalType={modalType}
              modalData={{
                item: state,
                configTitle: 'Edit task',
                updateTask: () => {
                  setModalType('')
                  getData().then()
                },
                updateTaskFunc
              }}
              closeModal={() => setModalType('')}
          />
        </div>
      </>
  )
}

export default TaskView

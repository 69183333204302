import React, { createContext, useState, useEffect } from 'react'
import {HeaderTimerType, NotificationType, TMainContext} from '../helpers/types'
import { getAccessToken } from '../service/token/token'
import { UserProfileResponse } from '../service/userProfile/userProfile.type'
import { getAllNotifications } from '../service/notifications'
import Centrifuge from "centrifuge";
import pushNotifications from "../helpers/pushNotifications";
import {TickerTypes} from "../components/other/Ticker/Ticker";

const MainContextProvider = createContext<TMainContext>({} as TMainContext)

const MainContext: React.FC = ({ children }) => {
  const [role, setRole] = useState(0)
  const [isShowMainHeader, setIsShowMainHeader] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loading, setLoading] = useState(true)
  const [modalType, setModalType] = useState('')
  const [modalData, setModalData] = useState('')
  const [userData, setUserData] = useState<UserProfileResponse | null>(null)
  const [userOnBoarding, setUserOnBoarding] = useState(false)
  const [notifications, setNotifications] = useState<NotificationType[]>([])
  const [approvedDeliverables, setApprovedDeliverables] = useState<any[]>([])
  const [googleAuthToken, setGoogleAuthToken] = useState('')
  const [centrifuge, setCentrifuge]  = useState<any>(null)
  const [headerNotification, setHeaderNotification] = useState<TickerTypes>('')
  const [unPainInvoice, setUnPaidInvoice] = useState(0)
  const [serverReloadStatus, setServerReloadStatus] = useState(0)
  const [isUnSubscribe, setIsUnSubscribe] = useState(false)
  const [headerTimer, setHeaderTimer] = useState<HeaderTimerType | null>(null)
  const [currentTimer, setCurrentTimer] = useState('loading')

  let notificationsTimeout: NodeJS.Timeout;

    const getNotifications = async () => {
        const res = await getAllNotifications()
        if (res.status !== 200) return false;
        setNotifications(res.data)
    }

    const connectOfferChat = () => {
        centrifuge.subscribe(`offers#${userData?.user_id}`, function(ctx: any) {
            if(ctx && ctx.data && ctx.data['comment-notification'] && ctx.data['comment-notification'].comments) {
                // console.log('check websocket offers message ', ctx.data['comment-notification'].comments)
                pushNotifications('New message', ctx.data['comment-notification'].comments.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, ''), `${process.env.REACT_APP_SITE_LINK}/offer/${ctx.data['comment-notification'].comments.offer_id}`)
            }
        })
    }

    const connectSocket = () => {
        centrifuge.subscribe(`notifications#${userData?.user_id}`, function(ctx: any) {
            console.log('ctx ctx ctx', ctx)
            pushNotifications('New notification', ctx.data.notification.text, `${process.env.REACT_APP_SITE_LINK}${ctx?.data?.notification?.url}`)
            if(ctx && ctx.data && ctx.data.notification) {
                console.log('notification notification', ctx)
                if (ctx.data.notification.type === 'SYSTEM') {
                    setModalType('server_reload')
                    // @ts-ignore
                    setModalData({ hideClose: true })
                }
                setNotifications(prevState => ([
                    ctx.data.notification,
                    ...prevState,
                ]))
            }
        });
    }

    const connectLegalRequestChat = () => {
        centrifuge.subscribe(`legal-requests#${userData?.user_id}`, function(ctx: any) {
            if(ctx && ctx.data && ctx.data['comment-notification'] && ctx.data['comment-notification'].comments) {
                pushNotifications('New message', ctx.data['comment-notification'].comments.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, ''), `${process.env.REACT_APP_SITE_LINK}/offer/${ctx.data['comment-notification']?.comments?.legal_request_id}`)
            }
        })
    }

    useEffect(() => {
        if(userData && centrifuge) connectOfferChat()
        if(userData && centrifuge) connectSocket()
        if(userData && centrifuge) connectLegalRequestChat()
    }, [centrifuge, userData])

    useEffect(() => {
        (async () => {
            if(isLoggedIn) await getNotifications()
            return () => clearTimeout(notificationsTimeout)
        })()
    }, [isLoggedIn])

  useEffect(() => {
    updateIsAuthorized()
  }, [])

  const setSingleNotificationContext = (id:number) => {
    setNotifications(notifications.filter((el) => el.id !== id))
  }

  const updateIsAuthorized = () => {
    const token = getAccessToken()
    setIsLoggedIn(!!token)
  }

  return (
    <MainContextProvider.Provider value={{
      role, setRole,
      modalType, setModalType,
      modalData, setModalData,
      isLoggedIn, setIsLoggedIn,
      loading, setLoading,
      userData, setUserData,
      notifications, setSingleNotificationContext, setNotifications,
      isShowMainHeader, setIsShowMainHeader,
      userOnBoarding, setUserOnBoarding,
      centrifuge, setCentrifuge,
      approvedDeliverables, setApprovedDeliverables,
      googleAuthToken, setGoogleAuthToken,
      headerNotification, setHeaderNotification,
      unPainInvoice, setUnPaidInvoice,
      serverReloadStatus, setServerReloadStatus,
      isUnSubscribe, setIsUnSubscribe,
      headerTimer, setHeaderTimer,
      currentTimer, setCurrentTimer
    }}
    >
      {children}
    </MainContextProvider.Provider>
  )
}

export { MainContext, MainContextProvider }
import React, {useContext, useEffect, useRef, useState} from "react";
import './CreateLegalRequest.scss'
import {createLegalRequestService, createNeedService, needListService} from "../../../service/client/legalRequest";
import toBottomIcon from '../../../assets/images/bottom.png'
import {CSSTransition} from "react-transition-group";
import {Input, ReactEditor, ReactSelect, TextArea} from "../../../components/UI";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {toastifyError} from "../../../service/toastify/toastify";
import {useHistory} from "react-router-dom";
import {MainContextProvider} from "../../../context/MainContext";
import {da} from "suneditor/src/lang";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import customAxios from "../../../service/api/interceptors";
import {useIntercom} from "react-use-intercom";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import {CompanyType} from "../../../types/company.type";

type NeedsItemType = {
    id: string,
    need: string
}

type NeedsDataType = {
    [key: string]: { items: NeedsItemType[] }
}

const isShowCreateLegalRequestButton = (clients: CompanyType[], client_id: string) => {
    const client_object: any = clients.find((el) => +el.id === +client_id)
    return client_object?.debt !== "unsubscribed";
}

export const CreateLegalRequest: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [needs, setNeeds] = useState<NeedsDataType>({} as NeedsDataType)
    const [choose, setChoose] = useState('')
    const [chooseTitle, setChooseTitle] = useState('')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [companies, setCompanies] = useState<any[]>([])
    const [values, setValues] = useState({title: '', describe: '', company_id: ''})
    const [errors, setErrors] = useState<{ [key: string]: string }>({})

    const { setModalType, userData } = useContext(MainContextProvider)
    const history = useHistory()
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsDropdownOpen(false) })
    const { showNewMessages } = useIntercom()

    const getNeedList = async () => {
        setLoading(true)
        try {
            const { data: clientsData } = await customAxios.get(`/company/company/get-companies`)
            setCompanies(clientsData)
            setValues({
                ...values,
                company_id: clientsData[0].id
            })

            const { data, status } = await needListService()
            if (status === 200 && data) {
                setNeeds({
                    'Custom Request': {
                        items: [
                            { id: '', need: 'Enter Custom Request' }
                        ]
                    },
                    ...data
                })
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }

        setLoading(false)
    }

    const createLegalRequest = async (id?: string) => {
        if(choose.length === 0) {
            toastifyError('You have to choose or create request')
            return false;
        }

        setLoading(true)
        try {
            const sendData = choose || id || ''
            const { status, data } = userData?.status.type_code === 100 ?
                await createLegalRequestService(sendData, values.company_id) :
                await customAxios.post(`/company/manager/kanban/create-legal-request`,{
                    legalNeedId: sendData, companyId: values.company_id
                })
            if((status === 200 || status === 201) && data) {
                setModalType('')
                history.push(`/legal-request/${data.id}`)
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const createNeed = async () => {
        const validReq = controlErrorFunc({ obj: values, config: { title: { required: true, maxLength: 255 }, describe: { maxLength: 2001 } } })
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }

        setLoading(true)
        try {
            const { data, status } = userData?.status.type_code === 100 ?
                await createNeedService(values.title, values.describe, values.company_id) :
                await customAxios.post(`/company/manager/kanban/create-custom-legal-request`, {
                    title: values.title, description: values.describe, companyId: values.company_id
                })
            if(data.id) {
                setModalType('')
                history.push(`/legal-request/${data.id}`)
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    useEffect(() => {
        getNeedList().then()
    }, [])

    const chooseItem = (id: string, need: string) => {
        setChoose(id)
        setIsDropdownOpen(false)
        setChooseTitle(need)
        // if(choose.includes(id)) {
        //     setChoose(choose.filter(el => el !== id))
        // } else {
        //     setChoose([...choose, id])
        // }
    }

    const chaster_left = 2001 - +(values.describe.replace(/&nbsp;/gi, '').trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').trim().length < 1 || (values.describe.trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')).length || 0)

    return (
        <div className={'create_legal_request__body'}>
            {loading && <ModalLoader />}
            <div className="create_legal_request__description">
                Choose from the list of options for your new request, or create a custom request
            </div>

            <ReactSelect
                className={'create_manager_offer__react-select'}
                value={values.company_id}
                options={companies.map(item => ({ value: item.id, label: item.name }))}
                onChange={e => setValues({ ...values, company_id: e.value })}
                valid={!errors?.client_id}
                errorMessage={errors.client_id}
            />
            <div className="create_legal_request__select" ref={dropdownRef}>
                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="create_legal_request__select-container">
                    <span>{chooseTitle || 'Choose option'}</span>
                    <img src={toBottomIcon} alt=""/>
                </div>
                <CSSTransition in={isDropdownOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                    <div className="create_legal_request__dropdown">
                        {
                            Object.keys(needs).map((key) => (
                                <div key={key} className={'create_legal_request__need-block'}>
                                    <b>{key}</b>
                                    <div className={'create_legal_request__need-items'}>
                                        {
                                            needs[key].items.map(({ need, id }) => (
                                                <div
                                                    key={id}
                                                    onClick={() => chooseItem(id, need)}
                                                    className={'create_legal_request__need-item'}>
                                                    <div className="create_legal_request__radio">
                                                        {
                                                            choose === id && <div className="create_legal_request__radio-active" />
                                                        }
                                                    </div>
                                                    <span>{need}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </CSSTransition>
            </div>

            <div className={'create_legal_request__custom-request'}>
                {!choose && <div className="create_legal_request__custom-request-heading">
                    OR CREATE CUSTOM REQUEST
                </div>}
                {!choose && <Input
                    className={'create_legal_request__custom-request-input'}
                    otherProps={{
                        value: values.title,
                        onChange: (e) =>
                            setValues({ ...values, title: e.target.value }),
                        placeholder: 'Title',
                        // disabled: !!choose
                    }}
                    valid={!errors.title} errorMessage={errors?.title}
                />}
                {!choose && <div style={{ marginTop: '10px' }}>
                    <ReactEditor
                        value={values.describe}
                        onChange={text => setValues({ ...values, describe: text })}
                        valid={!errors.describe} errorMessage={errors?.describe}
                    />
                    <em style={{ fontSize: '12px', color: '#9b9b9b', paddingLeft: '5px' }}>
                        {chaster_left > 0 ? chaster_left : 0} characters left
                    </em>
                </div>}
                {
                        <button
                            title={!isShowCreateLegalRequestButton(companies, values.company_id) ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                            disabled={!isShowCreateLegalRequestButton(companies, values.company_id)}
                            onClick={() => {
                                if(!choose) createNeed().then()
                                else createLegalRequest().then()
                            }}
                            className={'btn-black create_legal_request__custom-request-button'}>
                            SUBMIT
                        </button>
                }
            </div>
        </div>
    )
}
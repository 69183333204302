import ModalContainer from "../../../components/ModalContainer";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import customAxios from "../../../service/api/interceptors";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {Input, ReactEditor} from "../../../components/UI";

export const CreateRequestFromBG: React.FC<any> = ({ isOpen, close, project_suggestion, company_id }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<{ [key: string]: string }>({
        title: '',
        description: ''
    })
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const history = useHistory()

    useEffect(() => {
        if (isOpen && project_suggestion) {
            setValues({
                title: project_suggestion?.title,
                description: project_suggestion?.description
            })
        }
    }, [isOpen])

    const createRequest = async () => {
        const validReq = controlErrorFunc({ obj: values, config: {
                title: { required: true },
                description: { required: true }
            }})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/project-suggestion/${project_suggestion.id}/request`, {
                companyId: company_id,
                description: values.description,
                title: values.title
            })

            if (status === 200 || status === 201) {
                history.push(`/legal-request/${data?.id}`)
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div>
            <ModalContainer isOpen={isOpen} close={close} heading={'Create a new request'}>
                <div>
                    {loading && <ModalLoader />}
                    <div className={'mt15'}>
                        <Input
                            label={'Title'}
                            otherProps={{
                                value: values.title,
                                onChange: e => setValues({ ...values, title: e.target.value })
                            }}
                            valid={!errors?.title}
                            errorMessage={errors.title}
                        />
                    </div>
                    <div className={'mt15'}>
                        <ReactEditor
                            value={values.description}
                            onChange={e => setValues({ ...values, description: e })}
                            valid={!errors?.description}
                            errorMessage={errors.description}
                        />
                    </div>
                    <button onClick={createRequest} className={'w100 btn-black text-uppercase mt20'}>CREATE</button>
                </div>
            </ModalContainer>
        </div>
    )
}
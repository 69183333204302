import React, {useContext, Suspense, useEffect} from 'react'
import {
  Switch, Route, withRouter, Redirect,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { MainContextProvider } from '../../context/MainContext'
import ModalConfig from '../../components/ModalContainer/ModalConfig'
import Layout from './Layout/Layout'
import Loader from '../../components/other/Loader'
import UseRoutes from './UseRoutes'
import NotFound from '../NotFound/NotFound'
import ErrorBoundary from '../../components/other/ErrorBoundry/ErrorBoundry'
import {PrivacyPolicy} from "../Modals/Auth/PrivacyPolicy/PrivacyPolicy";
import { IntercomProvider } from 'react-use-intercom'
import {
    ProviderTermsUsePrivacyPolicy
} from "../ServiceProvider/ProviderTermsUsePrivacyPolicy/ProviderTermsUsePrivacyPolicy";

function requestPermission() {
  if (window.Notification && Notification.permission !== "denied") {
    Notification.requestPermission().then();
  }
}

const AppStack:React.FC<any> = (props) => {
  const { isLoggedIn, loading, userData, setModalType, setModalData } = useContext(MainContextProvider)

  useEffect(() => {
    const { search, pathname } = window.location
    if(!isLoggedIn && !localStorage.getItem('accessToken')) {
        localStorage.setItem('initialLink', pathname + search)
    }
    
    if(isLoggedIn && !localStorage.getItem('push')) {
      localStorage.setItem('push', 'true')
      setTimeout(requestPermission, 3000)
    }
  }, [isLoggedIn])

  const { routes, startUrl } = UseRoutes()

  // @ts-ignore
    return (
      <div className={userData?.status.type_code === 200 ? 'lawyer_layout' : 'client_layout'}>
        <CSSTransition in={loading} timeout={200} classNames={'slide'} unmountOnExit>
          <Loader />
        </CSSTransition>
        { routes.length
          ? isLoggedIn
            ? (
              <IntercomProvider appId={'ylxg44qe'} apiBase={'https://api-iam.intercom.io'}>
                <Layout {...props}>
                  <Suspense fallback={<Loader />}>
                      {/* @ts-ignore */}
                    <ErrorBoundary>
                    <TransitionGroup className={'transition-group-screen'}>
                      <CSSTransition
                        key={props.location}
                        timeout={{ enter: 500, exit: 0 }}
                        classNames={'slide'}
                      >
                        <Switch>
                          {
                            routes.map(({ path, Component, exact }) => (
                              <Route
                                key={path}
                                exact={exact}
                                path={path}
                                component={Component}
                              />
                            ))
                          }
                          <Route exact path={'/'} render={() => (<Redirect to={startUrl} />)} />
                          <Route path={'*'} exact component={NotFound} />
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                    </ErrorBoundary>
                  </Suspense>
                </Layout>
              </IntercomProvider>

                )
            : (
              <IntercomProvider appId={'ylxg44qe'} apiBase={'https://api-iam.intercom.io'}>
                <Switch>
                  {
                    routes.map(({ path, Component, exact }) => (
                      <Route
                        key={path}
                        exact={exact}
                        path={path}
                        component={Component}
                      />
                    ))
                  }
                  <Redirect from={'*'} to={startUrl} />
                </Switch>
              </IntercomProvider>
            )
          : null}
        <IntercomProvider appId={'ylxg44qe'} apiBase={'https://api-iam.intercom.io'}>
          <ModalConfig {...props} />
        </IntercomProvider>
        <ToastContainer />
        <PrivacyPolicy />
        {userData?.status.type_code === 200 && <ProviderTermsUsePrivacyPolicy />}
      </div>
  )
}

export default withRouter(AppStack)

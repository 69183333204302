import {Input, ReactSelect} from "../../../components/UI";
import {assignManagerToCompanyService, searchClientForEmailService} from "../../../service/manager/AddClientForManager";
import {useContext, useState} from "react";
import controlErrorValid from "../../../components/UI/ControlValid/controlErrorValid";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {toastifyError} from "../../../service/toastify/toastify";
import {da} from "suneditor/src/lang";
import {useHistory, useLocation} from "react-router-dom";
import {MainContextProvider} from "../../../context/MainContext";

type listItemType = {
    id: number
    manager_id: number
    name: string
    type: string
}

export const AttachClientToManager = (props: any) => {
    const [company, setCompany] = useState('')
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [resultList, setResultList] = useState<listItemType[]>([])
    const [finish, setFinish] = useState(false)
    const history = useHistory()

    const searchClientForEmail = async () => {
        const valid = controlErrorValid(email, { required: true, email: true })
        if(valid && typeof valid === 'string') {
            setErrorMessage(valid)
            return;
        }
        setErrorMessage('')

        setLoading(true)
        try {
            const { status, data } = await searchClientForEmailService(email)
            if(status !== 200) {
                toastifyError('Something went wrong')
                setLoading(false)
                return;
            }

            if(typeof data === 'string') {
                setErrorMessage(data)
            } else if(typeof data === 'object') {
                if(data.length > 0) {
                    setResultList(data)
                    setCompany(data[0].id)
                } else {
                    setErrorMessage(`The client does not exist or has already been assigned to a VLO`)
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    const { setModalType } = useContext(MainContextProvider)
    const assignManagerToCompany = async () => {
        const { status, data } = await assignManagerToCompanyService(+company)
        if(status === 200) {
            setFinish(true)
            setTimeout(() => {
                setModalType('')
                window.location.href = `/dashboard?client=${company}`
                //history.push(`/dashboard?client=${company}`)
            }, 1000)
        }
    }

    if(finish) {
        return <div>
            <p className={'create_manager_offer__description'}>Company have been assign</p>
        </div>
    }

    return (
        <div style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <p className={'create_manager_offer__description'}>Add a new client the workspace by entering their email.</p>
            {resultList.length === 0 ? <div style={{ marginTop: '10px' }}>
                <Input
                    otherProps={{
                        placeholder: 'Enter client email',
                        value: email,
                        onChange: e => setEmail(e.target.value)
                    }}
                    errorMessage={errorMessage} valid={!errorMessage}
                />
                <button onClick={searchClientForEmail} className={'btn-black'} style={{ width: '100%', marginTop: '30px' }}>Add new client</button>
            </div> :
            <div>
                <ReactSelect
                    value={company}
                    options={resultList.map(item => ({ label: item.name, value: item.id }))}
                    onChange={e => setCompany(e.value)}
                />
                <button onClick={assignManagerToCompany} className={'btn-black'} style={{ width: '100%', marginTop: '30px' }}>Submit</button>
            </div>}
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import customAxios from "../../../service/api/interceptors";
import ModalContainer from "../../../components/ModalContainer";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {Input, ReactEditor, ReactSelect} from "../../../components/UI";
import {ProjectSuggestionsType} from "../ProjectSuggestions.type";

type Props = {
    isOpen: boolean
    updateItems: () => void
    close: () => void
}

type UpdateProps = {
    isOpen: boolean
    updateItems: () => void
    close: () => void
    obj: ProjectSuggestionsType | null
}

const validationObj = {
    title: { required: true },
    description: { required: true, maxLength: 1000 },
    importance: { required: true },
    price_estimate: { number: true, required: true },
    time_estimate: { number: true, required: true }
}

const initialValues = {
    title: "",
    description: "",
    importance: 1,
    price_estimate: 0,
    time_estimate: 0
}

export const UpdateProjectSuggestion: React.FC<UpdateProps> = ({ isOpen, updateItems, close, obj }) => {
    const { id }: { id: string } = useParams()
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<{ [key: string]: string }>({})
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (isOpen && obj) {
            const { title, importance, description, price_estimate, time_estimate } = obj

            setValues({
                // @ts-ignore
                title, importance: importance + '', description, price_estimate, time_estimate
            })
        }
    },[isOpen])

    const createBusinessGoal = async () => {
        const validReq = controlErrorFunc({ obj: values, config: validationObj})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/project-suggestion/${obj?.id}`, {
                description: values.description,
                importance: values.importance,
                title : values.title,
                price_estimate : values.price_estimate,
                time_estimate : values.time_estimate,
            })

            if (status === 200 || status === 201) {
                close()
                updateItems()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <ProjectSuggestionModalJsx
            isOpen={isOpen}
            close={close}
            loading={loading}
            values={values}
            setValues={setValues}
            errors={errors}
            createBusinessGoal={createBusinessGoal}
            button={'Update'}
        />
    )
}

export const CreateProjectSuggestion: React.FC<Props> = ({ isOpen, updateItems, close }) => {
    const { id }: { id: string } = useParams()
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState<{ [key: string]: string }>({})
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (isOpen) {
            setValues(JSON.parse(JSON.stringify(initialValues)))
        }
    },[isOpen])

    const createBusinessGoal = async () => {
        const validReq = controlErrorFunc({ obj: values, config: validationObj})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/project-suggestion`, {
                description: values.description,
                importance: values.importance,
                goal_id: id,
                title : values.title,
                price_estimate : values.price_estimate,
                time_estimate : values.time_estimate,
            })

            if (status === 200 || status === 201) {
                close()
                updateItems()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <ProjectSuggestionModalJsx
            isOpen={isOpen}
            close={close}
            loading={loading}
            values={values}
            setValues={setValues}
            errors={errors}
            createBusinessGoal={createBusinessGoal}
        />
    )
}

const ProjectSuggestionModalJsx: React.FC<any> = ({ isOpen, close, loading, values, setValues,errors, createBusinessGoal, button }) => {
    return (
        <div className={'create_business_goal relative'}>
            <ModalContainer isOpen={isOpen} heading={'Suggestion  details'} close={close}>
                <div className={'relative'}>
                    {loading && <ModalLoader />}
                    <h3>Info</h3>
                    <div className={'mt15'}>
                        <Input
                            label={'Title'}
                            otherProps={{
                                value: values.title,
                                onChange: e => setValues({ ...values, title: e.target.value })
                            }}
                            valid={!errors?.title}
                            errorMessage={errors.title}
                        />
                    </div>
                    <div className={'mt15 flex'}>
                        <div className={'w50 mr5'}>
                            <Input
                                label={'Price Estimate'}
                                otherProps={{
                                    value: values.price_estimate,
                                    onChange: e => setValues({ ...values, price_estimate: e.target.value }),
                                    type: 'number'
                                }}
                                valid={!errors?.price_estimate}
                                errorMessage={errors.price_estimate}
                            />
                        </div>
                        <div className={'w50'}>
                            <Input
                                label={'Time estimate'}
                                otherProps={{
                                    value: values.time_estimate,
                                    onChange: e => setValues({ ...values, time_estimate: e.target.value }),
                                    type: 'number'
                                }}
                                valid={!errors?.time_estimate}
                                errorMessage={errors.time_estimate}
                            />
                        </div>
                    </div>
                    <div className={'mt15'}>
                        <ReactSelect
                            label={'Importance'}
                            value={values.importance || '1'}
                            options={[
                                { value: '1', label: 'Low' },
                                { value: '2', label: 'Medium' },
                                { value: '3', label: 'High' },
                            ]}
                            onChange={e => setValues({ ...values, importance: e.value })}
                            valid={!errors?.importance}
                            errorMessage={errors.importance}
                        />
                    </div>
                    <div className={'mt15'}>
                        <ReactEditor
                            value={values.description}
                            onChange={e => setValues({ ...values, description: e })}
                            valid={!errors?.description}
                            errorMessage={errors.description}
                        />
                    </div>
                    <button onClick={createBusinessGoal} className={'w100 btn-black text-uppercase mt20'}>{button || 'CREATE'}</button>
                </div>
            </ModalContainer>
        </div>
    )
}
import React, {useContext, useEffect} from "react";
import {MainContextProvider} from "../../../context/MainContext";

type FrozenControllerType = {
    debt: string | ''
}

export const FrozenController: React.FC<FrozenControllerType> = ({ debt }) => {
    const { setHeaderNotification, userData, setModalType, setModalData } = useContext(MainContextProvider)

    useEffect(() => {
        return () => {
            setHeaderNotification('')
            setModalData(null)
            setModalType('')
        }
    }, [])

    useEffect(() => {
        if ((userData?.status.type_code === 100) && debt) {
            if (debt === "warned") {
                setHeaderNotification("clientWarnTicker")
            }

            if (debt === "clientUnPainAllCompanies") {
                setHeaderNotification("clientUnPainAllCompanies")
            }

            if (debt === "frozen") {
                setModalType('frozen_modal')
                setModalData({ hideClose: true })
            }

            if (debt === "unsubscribed") {
                setHeaderNotification('unsubscribed')
            }
        }

        if ((userData?.status.type_code === 300 || userData?.status.type_code === 400) && debt) {
            if (debt === "frozen") {
                setHeaderNotification("managerWarnTicker")
            }

            if (debt === "unsubscribed") {
                setHeaderNotification('managerWarnTicker')
            }
        }
    }, [debt])

    return (
        <></>
    )
}
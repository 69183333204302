import React, {useContext, useState} from "react";
import './offerHeading.scss'
import {useTranslation} from "react-i18next";
import {MainContextProvider} from "../../../../context/MainContext";
import clientIcon from '../../../../assets/images/clickIcon.svg'
import {dateFormatter} from "../../../../helpers/dateFormatter";

type Props = {
    heading: string
    step: string
    text: string
    company_id?: number
    isNextStep: boolean
    isEdit?: boolean
    id?: number
    data: any
    isUpdateLegalRequest?: boolean
}

export const OfferHeading: React.FC<Props> = ({ heading, step, text, isNextStep, isEdit, id, company_id, data, isUpdateLegalRequest }) => {
    const { userData, setModalData, setModalType } = useContext(MainContextProvider)
    const { t } = useTranslation()
    const [readMore, setReadMore] = useState(false)

    return (
        <div className={'offerHeading'}>
            <div>
                {window.innerWidth > 500 && <div className="offerHeader__date">
                    <b>UPDATED ON: </b>
                    <span>{data.updated_at && dateFormatter(data.updated_at + '')}</span>
                </div>}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2 className={'offerHeading__heading'}>{heading}</h2>
                {/*{isEdit && <a
                    className={'offerHeading__edit'}
                    onClick={() => {
                    setModalData({
                        id,
                        company_id,
                        step: 2,
                        title: heading,
                        description: text,
                        configTitle: 'Update offer'
                    })
                    setModalType('create_manager_offer')
                }}>Edit</a>}

                {isUpdateLegalRequest && <a
                    className={'offerHeading__edit'}
                    onClick={() => {
                        setModalData({
                            id,
                            company_id,
                            step: 2,
                            title: heading,
                            description: text
                        })
                        setModalType('update_legal_request')
                    }}>Edit</a>}*/}
            </div>
            {/*{step && userData?.status.type_code !== 100 && <div className={'offerHeading__steps'}>
                <b className={'offerHeading__steps-next'}>Next step: </b>
                <span className={'offerHeading__steps-step'}>{step}</span>
            </div>}*/}
            <div className={'offerHeading__text task-body__description task-body__description-block'}>
                <div>
                    <p dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {userData?.status.type_code !== 100 && Array.isArray(data.client) && data.client[0] && data.client.map((item: any) => (
                    <div className="offerHeading__client">
                        <img
                            onClick={() => {
                                userData && setModalData(item.id)
                                userData && setModalType('client_profile_modal')
                            }}
                            src={item.path || item.avatar_path} alt="" style={{ width: '36px', height: '36px', borderRadius: '10px' }} />
                        <div>
                            <p>{item.fullname}</p>
                            <span>
                        <em>{item.title}</em>
                        {item.company?.name}
                    </span>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    )
}
import createIcon from "../icons/createKanbanIcon.svg"
import bsEmptyIcon from "../icons/bs-is-empty.svg"
import bsDoneIcon from "../icons/bs-is-done.svg"
import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import {CustomSortDropDown} from "../../LegalManager/ManagerKanban/SortKanbanBoard/SortKanbanBoard";
import "./business_goals_kanban.scss"
import customAxios from "../../../service/api/interceptors";
import {CompanyType} from "../../../types/company.type";
import ModalContainer from "../../../components/ModalContainer";
import {CreateBusinessGoal} from "../modals/CreateBusinessGoal/CreateBusinessGoal";
import {useHistory, useLocation} from "react-router-dom";
import {BusinessGoalType} from "../BusinessGoal.type";
import {CustomLoader} from "../../../components/UI/CustomLoader/CustomLoader";
import {ProjectSuggestionsType} from "../ProjectSuggestions.type";

export const BusinessGoalsVloKanban: React.FC = () => {
    const { setLoading, userData } = useContext(MainContextProvider)
    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [companyId, setCompanyId] = useState<null | string>(null)
    const [localLoading, setLocalLoading] = useState(true)
    const [items, setItems] = useState<BusinessGoalType[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    const getAllItems = async () => {
        setLocalLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/${searchParams.get('company') || companies[0].id}/business-goals`)
            if (status === 200) {
                setItems(data)
            }
        } catch (e) {}
        setLocalLoading(false)
    }

    const getClients = async () => {
        try {
            const { status, data } = await customAxios.get(`/company/company/get-companies`)
            if (status === 200) {
                setCompanies(data)
                //if (!searchParams.get('company')) history.push(`?company=${data[0].id}`)
            }
        } catch (e) {}
    }

    useEffect(() => {
        setLoading(false)
        if (companies.length > 0) {
            getAllItems().then()
        }

        document.title = 'Legal Roadmap'
    }, [location, companies])

    useEffect(() => {
        if (companies.length === 0) {
            getClients().then()
        }
    }, [])

    const getPriority = (priority: number) => {
        switch (priority) {
            case 1: return { value: 1, label: 'Low', color: '#000' }
            case 2: return { value: 2, label: 'Medium', color: '#FF9E45' }
            case 3: return { value: 3, label: 'High', color: '#F00' }
            default: return { value: '', label: 'Not Selected', color: '#ad5700' }
        }
    }
    
    const GetProgressBarData = ({ items }: { items: ProjectSuggestionsType[] }): any => {
        const width = 100 / items.length
        const obj: { [key: string]: {}[] } = { green: [], blue: [], grey: [] }
        items.map(el => {
            if (+el.is_done === 1) {
                obj.green.push({})
            } else if (!!el.offer && !Array.isArray(el.offer)) {
                obj.blue.push({})
            } else {
                obj.grey.push({})
            }
        })

        return <>
            {obj.green.length > 0 && obj.green.map(() => <div style={{background: '#57AC15', width: `${width}%`}}/>)}
            {obj.blue.length > 0 &&  obj.blue.map(() => <div style={{background: '#2361FF', width: `${width}%`}}/>)}
            {obj.grey.length > 0 && obj.grey.map(() => <div style={{background: '#F3F5FA', width: `${width}%`}}/>)}
            {
                items.length === 0 && <div style={{background: '#F3F5FA', width: `100%`}}/>
            }
        </>
    }

    const ProjectSuggestionsInCard: React.FC<{ items: ProjectSuggestionsType[] }> = ({ items }) => {
        const inProgressItems = items.filter(el => !!el.offer && !Array.isArray(el.offer) && +el.is_done !== 1)
        const isDoneItems = items.filter(el => +el.is_done === 1)
        //if (inProgressItems )

        if (items.length === 1) {
            console.log(items)
        }

        if ((items.length === isDoneItems.length) && items.length > 0) {
            return (
                <div className={'business_goals_kanban__bs-with-image flex-center'}>
                    <div className={'flex-center'}>
                        <img src={bsDoneIcon} alt=""/>
                        <p>
                            All works have <br />
                            been done
                        </p>
                    </div>
                </div>
            )
        }

        if (inProgressItems.length === 0) {
            return (
                <div className={'business_goals_kanban__bs-with-image flex-center'}>
                    <div className={'flex-center'}>
                        <img src={bsEmptyIcon} alt=""/>
                        <p>
                            There are currently no Works in progress, please SELECT Works To get started
                        </p>
                    </div>
                </div>
            )
        }

        if (inProgressItems.length > 0) {
            return (
                <div className={'business_goals_kanban__bs-items'}>
                    {
                        (inProgressItems || []).map((el, index) => {
                            if (index <= 1) {
                                return (
                                    <div className="business_goals_kanban__bs-item">
                                        <div>
                                            {el.title.length > 110 ? `${el.title.substr(0, 110)}...` : el.title}
                                        </div>
                                        <div style={{width: '5px', marginLeft: '5px'}}>
                                            <div className="business_goals_kanban__bs-item-circle"/>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                    {inProgressItems.length > 2 && <em>+{inProgressItems.length - 2} MORE WORKS</em>}
                </div>
            )
        }

        return <></>
    }
    
    const CardItemBsText: React.FC<{ items: ProjectSuggestionsType[] }> = ({ items }) => {
        const length = items.length
        const inProgressItems = items.filter(el => !!el.offer && !Array.isArray(el.offer) && +el.is_done !== 1)
        const isDoneItems = items.filter(el => +el.is_done === 1)

        return <>
            <div className={'flex-center'}>
                <div className="business_goals_kanban__item-progress-text-circle" style={{ background: '#57AC15' }} />
                Done ({isDoneItems.length}/{length})
            </div>
            {(isDoneItems.length > 0 && isDoneItems.length === length) ? <></> : <div className={'flex-center'} style={{marginLeft: '15px'}}>
                <div className="business_goals_kanban__item-progress-text-circle" style={{background: '#2361ff'}}/>
                In Progress ({inProgressItems.length}/{length})
            </div>}
        </>
    }

    if (localLoading || companies.length === 0) {
        return <CustomLoader />
    }

    return (
        <div className={'business_goals_kanban'}>
            <div className={'business_goals_kanban__header'}>
                <h2>Legal Roadmap</h2>
                {companies.length > 0 && <div className={'business_goals_kanban__header-select'}>
                    <CustomSortDropDown
                        onClick={(key) => {
                            history.push(`/business-goals?company=${key}`)
                        }}
                        value={searchParams.get('company') || companies[0].id + ''}
                        arr={companies.map(el => ({value: String(el.id), label: el.name}))}
                    />
                </div>}
            </div>
            <div className="business_goals_kanban__body">
                <div className="business_goals_kanban__body-items">
                    {userData?.status.type_code === 300 && <div onClick={() => setIsModalOpen(true)} className="business_goals_kanban__create-button">
                        <div>
                            <img src={createIcon} alt=""/>
                            <span>CREATE Goal</span>
                        </div>
                    </div>}
                    {
                        (items || []).map(item => (
                            <div className="business_goals_kanban__item">
                                <div className="business_goals_kanban__item-heading">
                                    {item.title}
                                </div>
                                <div className="business_goals_kanban__item-body">
                                    <div className="business_goals_kanban__item-priority">
                                        <span>Priority: <b style={{ color: getPriority(+item.priority).color }}>{getPriority(+item.priority).label}</b></span>
                                    </div>
                                    <div className={'business_goals_kanban__item-progress-text'}>
                                        <CardItemBsText items={item.project_suggestions} />
                                    </div>
                                    <div className={'business_goals_kanban__item-progress-line'}>
                                        <GetProgressBarData items={item.project_suggestions} />
                                    </div>
                                    <div className="business_goals_kanban__item-important">
                                        WORKS IN PROGRESS ({(item.project_suggestions || []).filter(el => !!el.offer && !Array.isArray(el.offer) && +el.is_done !== 1).length})
                                    </div>
                                    <ProjectSuggestionsInCard items={item.project_suggestions} />
                                </div>
                                <div
                                    onClick={() => history.push(`/business-goals/${item.id}`)}
                                    className="business_goals_kanban__item-button">
                                    <button>View</button>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <CreateBusinessGoal
                isOpen={isModalOpen}
                updateItems={getAllItems}
                close={() => setIsModalOpen(false)}
                company_id={searchParams.get('company') || companies[0].id}
            />
        </div>
    )
}
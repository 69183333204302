import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {ServiceProviderKanbanItems} from "./ServiceProviderKanbanComponents/ServiceProviderKanbanItems";
import "./service-provider-kanban.scss"
import {SortKanbanBoard} from "../../LegalManager/ManagerKanban/SortKanbanBoard/SortKanbanBoard";
import {KanbanTabs} from "../../LegalManager/ManagerKanban/KanbanTabs";
import {ServiceProviderKanbanItemType} from "./ServiceProviderKanbanComponents/serviceProviderKanbanItemType";

export type ServiceProviderKanbanType = {
    'client accepted': ServiceProviderKanbanItemType[],
    'done': ServiceProviderKanbanItemType[],
    'manager accepted': ServiceProviderKanbanItemType[],
    'new': ServiceProviderKanbanItemType[]
}

var service_provider_items_timeout: NodeJS.Timeout | null = null

export const ServiceProviderKanban = () => {
    const [kanban, setKanban] = useState<ServiceProviderKanbanType>({} as ServiceProviderKanbanType)
    const { setLoading } = useContext(MainContextProvider)

    const getKanbanData = async () => {
        try {
            const { status, data } = await customAxios.get(`provider/kanban?order_by=${localStorage.getItem('manager-kanban-sort-value') || 1}`)
            if (status === 200) {
                setKanban(data?.kanban)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getKanbanData().then()
        service_provider_items_timeout = setInterval(getKanbanData, 60000)
        document.title = 'Dashboard'
        return () => {
            // @ts-ignore
            clearInterval(service_provider_items_timeout)
        }
    }, [])

    useEffect(() => {

    }, [])

    return (
        <div className={'service-provider-kanban'}>
            <div className="service-provider-kanban__header">
                <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <h2 className="client-kanban-header__heading">Dashboard</h2>
                    {/*<KanbanTabs
                        hideFrozen
                        dropdownOptions={[{id:'', name: 'All'}, ...[{ id: '4', name: 'test', debt: 'none' }]]}
                    />*/}
                </div>
                <SortKanbanBoard onClick={(value) => window.location.reload()} />
            </div>
            <ServiceProviderKanbanItems
                kanban={kanban}
            />
        </div>
    )
}
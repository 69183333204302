export const all_countries = [
    { name: "Albania", code: 'AL', stripe: false, vat: '20' },
    { name: "Algeria", code: 'DZ', stripe: false, vat: '19' },
    { name: "Angola", code: 'AO', stripe: false, vat: '14' },
    { name: "Argentina", code: 'AR', stripe: false, vat: '21' },
    { name: "Armenia", code: 'AM', stripe: false, vat: '20' },
    { name: "Australia", code: 'AU', stripe: false, vat: '10' },
    { name: "Austria", code: 'AT', stripe: false, vat: '20' },
    { name: "Azerbaijan", code: 'AZ', stripe: false, vat: '18' },
    { name: "Bahrain", code: 'BH', stripe: false, vat: '10' },
    { name: "Barbados", code: 'BB', stripe: false, vat: '17.5' },
    { name: "Belgium", code: 'BE', stripe: false, vat: '21' },
    { name: "Bermuda", code: 'BM', stripe: false, vat: '0' },
    { name: "Bolivia", code: 'BO', stripe: false, vat: '13' },
    { name: "Bosnia and Herzegovina", code: 'BA', stripe: false, vat: '17' },
    { name: "Botswana", code: 'BW', stripe: false, vat: '12' },
    { name: "Brazil", code: 'BR', stripe: false, vat: '5-30' },
    { name: "British Virgin Islands", code: 'VG', stripe: false, vat: '0' },
    { name: "Bulgaria", code: 'BG', stripe: false, vat: '20' },
    { name: "Cabo Verde", code: 'CV', stripe: false, vat: '15' },
    { name: "Cambodia", code: 'KH', stripe: false, vat: '10' },
    { name: "Cameroon", code: 'CM', stripe: false, vat: '19.25' },
    { name: "Canada", code: 'CA', stripe: false, vat: '5-15' },
    { name: "Cayman Islands", code: 'KY', stripe: false, vat: '0' },
    { name: "Chad", code: 'TD', stripe: false, vat: '18' },
    { name: "Chile", code: 'CL', stripe: false, vat: '19' },
    { name: "China, People's Republic of", code: 'CN', stripe: false, vat: '13, 9, 6' },
    { name: "Colombia", code: 'CO', stripe: false, vat: '19' },
    { name: "Democratic Republic of the Congo", code: 'CD', stripe: false, vat: '16' },
    { name: "Republic of the Congo", code: 'CG', stripe: false, vat: '18.9' },
    { name: "Costa Rica", code: 'CR', stripe: false, vat: '13' },
    { name: "Croatia", code: 'HR', stripe: false, vat: '25' },
    { name: "Cyprus", code: 'CY', stripe: false, vat: '19' },
    { name: "Czech Republic", code: 'CZ', stripe: false, vat: '21' },
    { name: "Denmark", code: 'DK', stripe: false, vat: '25' },
    { name: "Dominican Republic", code: 'DO', stripe: false, vat: '18' },
    { name: "Ecuador", code: 'EC', stripe: false, vat: '12' },
    { name: "Egypt", code: 'EG', stripe: false, vat: '14' },
    { name: "El Salvador", code: 'SV', stripe: false, vat: '13' },
    { name: "Equatorial Guinea", code: 'GQ', stripe: false, vat: '15' },
    { name: "Estonia", code: 'EE', stripe: false, vat: '20' },
    { name: "Eswatini", code: 'SZ', stripe: false, vat: '15' },
    { name: "Ethiopia", code: 'ET', stripe: false, vat: '15' },
    { name: "Fiji", code: 'FJ', stripe: false, vat: '9, 15' },
    { name: "Finland", code: 'FI', stripe: false, vat: '24' },
    { name: "France", code: 'FR', stripe: false, vat: '20' },
    { name: "Gabon", code: 'GA', stripe: false, vat: '18' },
    { name: "Georgia", code: 'GE', stripe: false, vat: '18' },
    { name: "Germany", code: 'DE', stripe: false, vat: '19' },
    { name: "Ghana", code: 'GH', stripe: false, vat: '15' },
    { name: "Gibraltar", code: 'GI', stripe: false, vat: '0' },
    { name: "Greece", code: 'GR', stripe: false, vat: '24' },
    { name: "Greenland", code: 'GL', stripe: false, vat: '0' },
    { name: "Guatemala", code: 'GT', stripe: false, vat: '12' },
    { name: "Guernsey, Channel Islands", code: 'GG', stripe: false, vat: '0' },
    { name: "Guyana", code: 'GY', stripe: false, vat: '14' },
    { name: "Honduras", code: 'HN', stripe: false, vat: '15' },
    { name: "Hong Kong SAR", code: 'HK', stripe: false, vat: '0' },
    { name: "Hungary", code: 'HU', stripe: false, vat: '27' },
    { name: "Iceland", code: 'IS', stripe: false, vat: '24' },
    { name: "India", code: 'IN', stripe: false, vat: '5-28' },
    { name: "Indonesia", code: 'ID', stripe: false, vat: '11' },
    { name: "Iraq", code: 'IQ', stripe: false, vat: '0' },
    { name: "Ireland", code: 'IE', stripe: false, vat: '23' },
    { name: "Isle of Man", code: 'IM', stripe: false, vat: '20' },
    { name: "Israel", code: 'IL', stripe: false, vat: '17' },
    { name: "Italy", code: 'IT', stripe: false, vat: '22' },
    { name: "Ivory Coast (Côte d'Ivoire)", code: 'CI', stripe: false, vat: '18' },
    { name: "Jamaica", code: 'JM', stripe: false, vat: '15' },
    { name: "Japan", code: 'JP', stripe: false, vat: '10' },
    { name: "Jersey, Channel Islands", code: 'JE', stripe: false, vat: '5' },
    { name: "Jordan", code: 'JO', stripe: false, vat: '16' },
    { name: "Kazakhstan", code: 'KZ', stripe: false, vat: '12' },
    { name: "Kenya", code: 'KE', stripe: false, vat: '16' },
    { name: "South Korea", code: 'KR', stripe: false, vat: '10' },
    { name: "Kosovo", code: 'KS', stripe: false, vat: '18' },
    { name: "Kuwait", code: 'KW', stripe: false, vat: '0' },
    { name: "Kyrgyzstan", code: 'KG', stripe: false, vat: '12' },
    { name: "Lao PDR", code: 'LA', stripe: false, vat: '7' },
    { name: "Latvia", code: 'LV', stripe: false, vat: '21' },
    { name: "Lebanon", code: 'LB', stripe: false, vat: '11' },
    { name: "Libya", code: 'LY', stripe: false, vat: '0' },
    { name: "Liechtenstein", code: 'LI', stripe: false, vat: '7.7' },
    { name: "Lithuania", code: 'LT', stripe: false, vat: '21' },
    { name: "Luxembourg", code: 'LU', stripe: false, vat: '16' },
    { name: "Macau SAR", code: 'MO', stripe: false, vat: '0' },
    { name: "Madagascar", code: 'MG', stripe: false, vat: '20' },
    { name: "Malawi", code: 'MW', stripe: false, vat: '16.5' },
    { name: "Malaysia", code: 'MY', stripe: false, vat: '6' },
    { name: "Maldives, Republic of", code: 'MV', stripe: false, vat: '8' },
    { name: "Malta", code: 'MT', stripe: false, vat: '18' },
    { name: "Mauritania", code: 'MR', stripe: false, vat: '16' },
    { name: "Mauritius", code: 'MU', stripe: false, vat: '15' },
    { name: "Mexico", code: 'MX', stripe: false, vat: '16' },
    { name: "Moldova", code: 'MD', stripe: false, vat: '20' },
    { name: "Mongolia", code: 'MN', stripe: false, vat: '10' },
    { name: "Montenegro", code: 'ME', stripe: false, vat: '21' },
    { name: "Morocco", code: 'MA', stripe: false, vat: '20' },
    { name: "Mozambique", code: 'MZ', stripe: false, vat: '16' },
    { name: "Myanmar", code: 'MM', stripe: false, vat: '5' },
    { name: "Namibia, Republic of", code: 'NA', stripe: false, vat: '15' },
    { name: "Netherlands", code: 'NL', stripe: false, vat: '21' },
    { name: "New Zealand", code: 'NZ', stripe: false, vat: '15' },
    { name: "Nicaragua", code: 'NI', stripe: false, vat: '15' },
    { name: "Nigeria", code: 'NG', stripe: false, vat: '7.5' },
    { name: "North Macedonia", code: 'MK', stripe: false, vat: '18' },
    { name: "Norway", code: 'NO', stripe: false, vat: '25' },
    { name: "Oman", code: 'OM', stripe: false, vat: '5' },
    { name: "Pakistan", code: 'PK', stripe: false, vat: '13-16' },
    { name: "Palestinian territories", code: 'PS', stripe: false, vat: '16' },
    { name: "Panama", code: 'PA', stripe: false, vat: '7' },
    { name: "Papua New Guinea", code: 'PG', stripe: false, vat: '10' },
    { name: "Paraguay", code: 'PY', stripe: false, vat: '10' },
    { name: "Peru", code: 'PE', stripe: false, vat: '18' },
    { name: "Philippines", code: 'PH', stripe: false, vat: '12' },
    { name: "Poland", code: 'PL', stripe: false, vat: '23' },
    { name: "Portugal", code: 'PT', stripe: false, vat: '23' },
    { name: "Puerto Rico", code: 'PR', stripe: false, vat: '11.5' },
    { name: "Qatar", code: 'QA', stripe: false, vat: '0' },
    { name: "Romania", code: 'RO', stripe: false, vat: '19' },
    { name: "Rwanda", code: 'RW', stripe: false, vat: '18' },
    { name: "Saint Lucia", code: 'LC', stripe: false, vat: '12.5' },
    { name: "Saudi Arabia", code: 'SA', stripe: false, vat: '15' },
    { name: "Senegal", code: 'SN', stripe: false, vat: '18' },
    { name: "Serbia", code: 'RS', stripe: false, vat: '20' },
    { name: "Singapore", code: 'SG', stripe: false, vat: '8' },
    { name: "Slovak Republic", code: 'SK', stripe: false, vat: '20' },
    { name: "Slovenia", code: 'SI', stripe: false, vat: '22' },
    { name: "South Africa", code: 'ZA', stripe: false, vat: '15' },
    { name: "Spain", code: 'ES', stripe: false, vat: '21' },
    { name: "Sri Lanka", code: 'LK', stripe: false, vat: '15' },
    { name: "Sweden", code: 'SE', stripe: false, vat: '25' },
    { name: "Switzerland", code: 'CH', stripe: false, vat: '7.7' },
    { name: "Taiwan", code: 'TW', stripe: false, vat: '5' },
    { name: "Tajikistan", code: 'TJ', stripe: false, vat: '15' },
    { name: "Tanzania", code: 'TZ', stripe: false, vat: '18' },
    { name: "Thailand", code: 'TH', stripe: false, vat: '7' },
    { name: "Timor-Leste", code: 'TL', stripe: false, vat: '0' },
    { name: "Trinidad and Tobago", code: 'TT', stripe: false, vat: '12.5' },
    { name: "Tunisia", code: 'TN', stripe: false, vat: '19' },
    { name: "Turkey", code: 'TR', stripe: false, vat: '20' },
    { name: "Turkmenistan", code: 'TM', stripe: false, vat: '15' },
    { name: "Uganda", code: 'UG', stripe: false, vat: '18' },
    { name: "Ukraine", code: 'UA', stripe: false, vat: '20' },
    { name: "United Arab Emirates", code: 'AE', stripe: false, vat: '5' },
    { name: "United Kingdom", code: 'GB', stripe: false, vat: '20' },
    { name: "United States", code: 'US', stripe: false, vat: '0' },
    { name: "Uruguay", code: 'UY', stripe: false, vat: '22' },
    { name: "Uzbekistan, Republic of", code: 'UZ', stripe: false, vat: '12' },
    { name: "Venezuela", code: 'VE', stripe: false, vat: '16' },
    { name: "Vietnam", code: 'VN', stripe: false, vat: '10' },
    { name: "Zambia", code: 'ZM', stripe: false, vat: '16' },
    { name: "Zimbabwe", code: 'ZW', stripe: false, vat: '14.5' },
]
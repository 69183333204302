import React from 'react'
import 'suneditor/dist/css/suneditor.min.css'
import { useTranslation } from 'react-i18next'
import { Editor } from '@tinymce/tinymce-react';
import './react-editor.scss'

type Props = {
  value: string
  onChange: (key: string) => void
  onKeyDown?: (key: React.KeyboardEvent) => void
  valid?: boolean
  errorMessage?: string,
  height?: number,
  disabled?: boolean
  className?: string
  placeholder?: string
  onInit?: () => void
}

const ReactEditor:React.FC<Props> = ({
  value, onChange, onKeyDown, valid, errorMessage, height= 300, disabled, className, placeholder, onInit
}) => {
  const { t } = useTranslation()

    const changeText = (text: string) => {
      // let changedText = text.replace(/(style=".*?)font-family.*?;/g, '$1')
        let changedText = text
        changedText = changedText.replace(/<h[1-6].*?>(.*?)<\/h[1-6]>/g, '<p>$1</p>')
        changedText = changedText.replace(/class="[^"]*"/, '');
        const regex = /style="[^"]*(font-size|line-height|background-color|border-radius|color|margin|margin-top|margin-bottom|margin-right|margin-left|padding|padding-top|padding-bottom|padding-right|padding-left|opacity|position|display|width|height|text-align|list-style|list-style-type|flex-direction)[^"]*"/g;
        changedText = changedText.replace(regex, '')
        return changedText
    }

    return  (
    <div className={`${className} react-editor`}>
        {/* @ts-ignore */}
        <Editor
            value={value}
            apiKey='ltncv51bpcokmuet6cgwju1lct3joea9pxyn12aqnuy7qy6t' // test@space-level.com
            init={{
                height: height,
                plugins: [
                    'hr pagebreak nonbreaking anchor toc insertdatetime /*advlist*/ lists wordcount imagetools textpattern noneditable charmap emoticons code'
                ],
                menubar: '',
                toolbar: 'bold italic ' +
                    ' |  numlist bullist' +
                    ' insertfile template',
                toolbar_sticky: false,
                language: 'ru',
                placeholder: placeholder,
            }}
            // outputFormat={'text'}
            // onPaste={(e) => console.log(e)}
            onEditorChange={(text) =>
                onChange(changeText(text))}
            disabled={disabled}
            onInit={onInit}

        />
      {!valid && <span className={'react-editor__error-message'}>{errorMessage || t('requiredField')}</span>}
    </div>
  )
}

export default ReactEditor
